ACC.langcurrency = {

	_autoload: [
		"bindLangCurrencySelector"
	],

	bindLangCurrencySelector: function() {

		$('#lang-selector').change(function() {
			$('#lang-form').submit();
		});

		$('#currency-selector').change(function() {
			$('#currency-form').submit();
		});
	}
};
//JS for Language Toggler
$(".js-lang-toggler").on('click', (e) => {
	let clickedValue = $(e.currentTarget).attr('data-value');
	if (clickedValue === 'en' && $('#lang-selector option[value="en"]').length > 0) {
		$('#lang-selector option[value="en"]').prop('selected', true);
		$('#lang-selector option[value="es"]').removeAttr('selected');
		$('#lang-form').submit();
	} else {
		$('#lang-selector option[value="es"]').prop('selected', true);
		$('#lang-selector option[value="en"]').removeAttr('selected');
		$('#lang-form').submit();
	}
})