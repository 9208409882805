var leaseAppData = null;
var leaseApprovalUrl = $('#leaseApprovalUrl').attr('data-value');
var locationGuid = $('#locationGuid').attr('data-value');
var acimaMarketPlaceUrl = $('#acimaMarketPlaceUrl').attr('data-value');
var zipCode = $('#zipCode').attr('data-value');

// Listening to Message events
$(document).ready(function() {
    $(window).on("message", function(e) {
        let data = e.originalEvent.data;
        if (!isNullUndefined(data)) {
            if (typeof data !== 'object') {
                data = JSON.parse(data);
            }
            if (data.type === "ACIMA_ECOM_IFRAME_READY") {
                ACIMA.loadingSpinnerHide();
            }
            if (data.type === "ACIMA_ECOM_IFRAME_CLOSE") {
                if (!isNullUndefined(leaseAppData) && leaseAppData.appStatus === "Approved") {
                    ACIMA.loadingSpinnerShow();
                    validateLease(leaseAppData.applicationId);
                }
                if (!isNullUndefined(leaseAppData) && leaseAppData.appStatus === "Denied") {
                    ACIMA.loadingSpinnerShow();
                    window.location.href = acimaMarketPlaceUrl;
                }
            }
        }
    });

});

// Acima Preapproval Iframe Script

$(".shopNowLink").on("click", function() {
	launchApprovalModal();
});

$(".applyToShopButton").on("click", function() {
	$('#integratedhowToShopPopUP').modal('hide');
	launchApprovalModal();
});

function launchApprovalModal() {
	ACIMA.loadingSpinnerShow();
	locationGuid = $('#locationGuid').attr('data-value');
	const acima = new Acima.Client({
		merchantId: `${locationGuid}`,
		iframeUrl: `${leaseApprovalUrl}`
	});
	const pageUID = $('#acimaMpPageUID').attr('data-value');
	acima.preapproval({
			// The customer object and all of its fields are optional
			// The customer can fill or modify them within the iframe
			customer: {},
		})
		// Listen for the pre-approval result
		.then((response) => {
			const leaseApprovalData = response;
			if (pageUID === "acimaMarketplacePage" || pageUID === "acimaMarketplaceDetailsPage") {
				handleApprovalIframeResponse(leaseApprovalData);
			} else {
				if (isNullUndefined(leaseApprovalData)) {
					/* user closed iframe without completing application */
				} else {
					leaseAppData = leaseApprovalData;
					if (leaseApprovalData.appStatus == "Approved") {
						setTimeout(function() {
							validateLease(leaseApprovalData.applicationId);
						}, $('#acimaAppIframeClosingTime').attr('data-value'));
					}
					if (leaseApprovalData.appStatus == "Denied") {
						setTimeout(function() {
							window.location.href = acimaMarketPlaceUrl;
						}, $('#acimaAppIframeClosingTime').attr('data-value'));
					}
				}
			}
		})
		// Listen for any errors or interruptions in the customer's application
		.catch(({
			code,
			message
		}) => {
			if (pageUID === "acimaMarketplacePage" || pageUID === "acimaMarketplaceDetailsPage") {
			}
			else {
				/* do something here */
				window.location.href = acimaMarketPlaceUrl;
			}
		})
}

function handleApprovalIframeResponse(leaseApprovalData) {
	if (isNullUndefined(leaseApprovalData)) {
		/* user closed iframe without completing application */
	} else {
		leaseAppData = leaseApprovalData;
		if (leaseApprovalData.appStatus == "Approved") {
			setTimeout(function() {
				const acimaMpVendorUrl = $('#acimaMpVendorURL').attr('data-value');
				window.location.href = acimaMpVendorUrl;
			}, $('#acimaAppIframeClosingTime').attr('data-value'));
		}
		if (leaseApprovalData.appStatus == "Denied") {
			setTimeout(function() {
				window.location.href = ACC.config.encodedContextPath + "/marketplace";
			}, $('#acimaAppIframeClosingTime').attr('data-value'));
		}
	}
}