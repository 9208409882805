ACC.cookie = {

 getCookie:function(cookieName){
    const cookie= `${cookieName}=`;
    const decodeCookie=decodeURIComponent(document.cookie);
    const cookieArray=decodeCookie.split(';');
        for(let i=0; i<cookieArray.length;i+=1){
            let cookieObj=cookieArray[i];
            while(cookieObj.charAt(0)===' '){
            cookieObj=cookieObj.substring(1);
            }
            if(cookieObj.indexOf(cookieName)===0){
                return cookieObj.substring(cookie.length,cookieObj.length);
            }
        }
    },

    getMatchingCookies:function(cookieName){
        let matchingCookies= document.cookie.split(';').filter(c => c.trim().startsWith(cookieName));
        return matchingCookies;
    },

    getFormattedCookies: function(getCookies){
        const leadDasrc = ACC.cookie.getCookie(ACC.config.acimaDasrcCookie);
        const leadDavuid = ACC.cookie.getCookie(ACC.config.acimaDavuidCookie);
        const cookiePairs = getCookies.map(cookie => {
            const [cookieName, cookieValue] = cookie.split('=').map(part => part.trim());
            return `${cookieName}:${cookieValue}`;
        });
        if (leadDasrc) {
            cookiePairs.push(`dasrc:${leadDasrc}`);
        }
        if (leadDavuid) {
            cookiePairs.push(`davuid:${leadDavuid},`);
        }
        return `{${cookiePairs.join(',')}}`;
    }
}