ACC.autocomplete = {

	bindAll: function () {
		this.bindSearchAutocomplete();
		this.bindDisableSearch();
	},

	bindSearchAutocomplete: function () {
		// extend the default autocomplete widget, to solve issue on multiple instances of the searchbox component
		$.widget("custom.yautocomplete", $.ui.autocomplete, {
			_create: function () {

				// get instance specific options form the html data attr
				var option = this.element.data("options");
				// set the options to the widget
				this._setOptions({
					minLength: option.minCharactersBeforeRequest,
					displayProductImages: option.displayProductImages,
					delay: option.waitTimeBeforeRequest,
					autocompleteUrl: option.autocompleteUrl,
					source: this.source
				});

				// call the _super()
				$.ui.autocomplete.prototype._create.call(this);

			},
			options: {
				cache: {}, // init cache per instance
				focus: function () { return false; }, // prevent textfield value replacement on item focus
				select: function (event, ui) {
					ui.item.value = ACC.sanitizer.sanitizeSelect(ui.item.value);
					window.location.href = ui.item.url;
				}
			},
			_renderItem: function (ul, item) {

				if (item.type == "autoSuggestion") {
					var renderHtml = $("<a>").attr("href", item.url)
						.append($("<div>").addClass("name").text(item.value));
					return $("<li>")
						.data("item.autocomplete", item)
						.append(renderHtml)
						.appendTo(ul);
				}
				else if (item.type == "productResult") {
					var renderHtml = $("<a>").attr("href", item.url)
						.append(
							item.image
								? $("<div>").addClass("thumb")
									.append($("<img>").attr("src", item.image))
								: null
						)
						.append($("<div>").addClass("name").html(ACC.sanitizer.sanitize(item.value)))
						.append($("<div>").addClass("price").text(item.price));

					return $("<li>")
						.data("item.autocomplete", item)
						.append(renderHtml)
						.appendTo(ul);
				}
			},
			source: function (request, response) {
				var self = this;
				var term = request.term.toLowerCase();
				if (term in self.options.cache) {
					return response(self.options.cache[term]);
				}

				$.getJSON(self.options.autocompleteUrl, { term: request.term }, function (data) {
					if (data.suggestions.length != 0 && data.suggestions != null) {
						$("#SuggestionContainerId").removeClass("d-none");
						$.each(data.suggestions, function (i, obj) {
							$("#suggesstion").append(
								$(`<a href="${ACC.config.encodedContextPath + "/search?text=" + encodeURIComponent(obj.term)}" class="SuggesstionItem"> 
								<p class="suggesstion-Text mb-0 font16 fontMob14">${checkWordPosition(obj.term)}</p></a>`))
						});
					}
					else {
						$("#SuggestionContainerId").addClass("d-none");
					}

					if (data.products.length != 0 && data.products != null) {
						$("#ProductContainerId").removeClass("d-none");
						$.each(data.products, function(i, obj) {
							$("#products").append(
								$(`<a href="${ACC.config.encodedContextPath + obj.url}" class="ProductItem d-flex align-items-center"> 
								<img style="width: 42px;height: 42px;" src="${(obj.images != null && self.options.displayProductImages) ? obj.images[0].url + '&tr=w-42,h-42,fo-center' : ACC.config.contextPath + "/_ui/responsive/theme-acima/images/missing_product_EN_300x300.jpg"}"/>   
								<p class="ml-20 font-primary fw-500 mb-0 font16 fontMob14"> ${checkWordPositionForProductList(ACC.sanitizer.sanitize(obj.name))} </p> </a>`))
						});
					}
					else {
						$("#ProductContainerId").addClass("d-none")
					}
				});
			}

		});


		$search = $(".js-site-search-input");
		if ($search.length > 0) {
			$search.yautocomplete()
		}

		function checkWordPosition(text) {
			searchText = $("#js-site-search-input").val().length;
			splitTextForBold = text.slice(searchText, text.length);
			splitText = text.slice(0, searchText);
			return `${splitText}<strong>${splitTextForBold}</strong>`;
		}

		function checkWordPositionForProductList(text) {
			return !text.includes("search-results-highlight") ?
				text.split(" ").map(x => x.toLowerCase().includes($("#js-site-search-input").val().toLowerCase()) ? `<span class="font-normal">${x}</span>` : x).toString().replaceAll(',', ' ') : text;
		}
	},

	bindDisableSearch: function () {
		$('#js-site-search-input').keyup(function () {
			$('#js-site-search-input').val($('#js-site-search-input').val().replace(/^\s+/gm, ''));
			$('.js_search_button').prop('disabled', this.value == "" ? true : false);
			$('#js-site-search-input').val().length > 0 ? $("#PopularSearchesContainerId").addClass("d-none") : $("#PopularSearchesContainerId").removeClass("d-none");
			$('#js-site-search-input').val().length < 3 ? $("#SuggestionContainerId").addClass("d-none") : '';
			$('#js-site-search-input').val().length < 3 ? $("#ProductContainerId").addClass("d-none") : '';
			$("#ViewAllProductsSearch").attr("href", ACC.config.encodedContextPath + "/search?text=" + $('#js-site-search-input').val().replace(/^\s+/gm, ''));
			$("#js-site-search-input").val() === '' ? $(".site-search .cross-sign").addClass('d-none') : $(".site-search .cross-sign").removeClass('d-none');
		})
		$(".site-search .cross-sign").click(function() {
			$("#js-site-search-input").val("");
			$("#js-site-search-input").focus();
			$(this).addClass('d-none');
		});
	}
}
$(document).ready(function () {
	ACC.autocomplete.bindAll();
});